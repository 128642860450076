.darkWave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 10px;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.darkWave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 82px;
  transform: rotateY(180deg);
}

.darkWave .shape-fill {
  fill: url("#dark");
}
