.projectx{
    height: 85vh;
    position: relative;
    padding-left: 5vw;
    padding-top: 10vh;
    font-family: "Oswald", sans-serif;
}


.projectx-header{
    padding-right: 5vw;
    text-align: center;
    margin-bottom: 20px;
}

.projectx__main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 5vh;
}
.projectx__container {
    position: relative;
    width: 30%; /* Adjust width as needed */
    padding: 10px;
}

.projectx__image{
    width: 100%;
    border-radius: 15px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.hover-text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7); /* Translucent background */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s;
    font-size: 24px; 
    border-radius: 15px;
}

.projectx__container:hover .hover-text {
    opacity: 1;
}

@media screen and (max-width: 768px) {
    .projectx__container {
        width: 100%;
        text-align: center;
    }

    .projectx__image {
        width: 50%;
    }

    .projectx-header
    {
        font-size: 48px;
    }
    
}