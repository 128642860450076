.leftSocialMediaIcons__mobileLeftBar {
  display: none;
}

.leftSocialMediaIcons__mobileRightBar {
  display: none;
}

.leftSocialMediaIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 5%;
  bottom: 0;
}

.leftSocialMediaIcons__verticalBar {
  border-left: 3px solid var(--purple);
  height: 25vh;
}

.icon {
  color: #22262b;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  animation: moveDownAnimation ease 3s;
  padding-top: 5px;
}

.icon:hover {
  color: var(--purple);
  transform: scale(1.1);
}

.mobile__hr {
  width: 35vw;
  height: 2px;
  border: none;
  margin: 20px auto;
  background-color: var(--purple);
  animation: fadeInAnimation ease 3s;
}

@media only screen and (max-width: 768px) {
  .leftSocialMediaIcons {
    flex-direction: row;
    left: 50%;
    bottom: 20%;
    transform: translateX(-50%);
  }

  .leftSocialMediaIcons__verticalBar {
    display: none;
  }

  .leftSocialMediaIcons__mobileLeftBar {
    display: block;
    margin-right: 10px;
  }

  .leftSocialMediaIcons__mobileRightBar {
    display: block;
    margin-left: 10px;
  }

  .icon {
    position: inherit;
    padding: 0 15px;
    animation: none;
  }
}
